@import url('https://fonts.googleapis.com/css2?family=Inter&family=Martel:wght@700;800&family=Montserrat:wght@300;400;700&family=Open+Sans:wght@700&family=Roboto:wght@100;300;400;500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
}

/* HEADER */
.hero {
    height: 500px;
    background-image: url('/public/img/Banner_J1StRegis.jpg');
    background-size: cover;
    display: flex;
    align-items: center;
}

.hero h1 {
    font-size: 2em;
    font-weight: 700;
}

.hero p {
    font-size: 0.99em;
    font-weight: 700;
}

.hero .btn {
    color: #fff;
    background-color: #E31E28;
    font-weight: 700;
}

/* ----- */

/* WHY SECTION */
.why-title {
    text-align: left;
    font-size: 2em;
    margin-top: 50px;
    font-weight: 700;
}

.box-benefit {
    margin: auto;
    margin-bottom: 20px;
    padding: 20px;
    border: 2px solid #bab8b8;
    border-radius: 4px;
}

.box-benefit i{
    font-size: 2em;
    color: #E31E28;
}

.box-benefit .title {
    margin: 15px 0;
    font-weight: 700;
}

#why-section {
    margin-bottom: 80px;
}

.text-bold {
    font-weight: bolder;
}

.margin-bottom {
    margin-bottom: 20px;
}

/* ---------- */

/* About Us */
.about-us {
    background-color: #ED1C24;
    color: #fff;
    border-radius: 20px;
}

.about-us .title {
    font-size: 2em;
    font-weight: 700;
}

.about-us .btn {
    color: #005FAF;
    background-color: #fff;
    font-weight: 700;
}
/* ------- */

/* About Us */
.testimoni {
    background-color: #3D445C;
    color: #fff;
    border-radius: 20px;
}

.testimoni .title {
    font-size: 2em;
    font-weight: 700;
}

.testimoni .btn {
    color: #005FAF;
    background-color: #fff;
    font-weight: 700;
}
/* ------- */

/* Programs */
#program {
    padding: 50px 0;
    background-color: #F6F8F7;
}

#program .title {
    text-align: center;
    font-weight: 700;
    font-size: 2em;
    margin-bottom: 50px;
}

.box-program {
    text-align: center;
    background-color: #bab8b8;
    height: 150px;
    margin: 0 auto 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0;
    background-color: #ffffff;
    color: #005FAF;
    border-radius: 5px;
    box-shadow: 0px 10px 11px 7px rgba(0,0,0,0.1);
}

.box-program.odd {
    background-color: #005FAF;
    color: #fff;
}

.box-program i {
    font-size: 1.5em;
}

.box-program h5 {
    font-weight: 700;
}

.box-program hr {
    width: 60px;
    height: 3px;
    margin: 20px auto;
    background-color: #5a5a5a;
}

.box-program hr.odd {
    background-color: #ffffff;
}
/* ------- */

/* Consultation */
#consultation {
    padding: 90px 0;
}

.content1 h3, .content1 p {
    color: #fff;
    margin: 0 auto;
}

.content1 a {
    color: #fff;
}

.content1 h3 {
    margin-bottom: 20px;
    font-weight: 700;
}

.content2 .btn {
    font-size: 0.8em;
    padding: 10px;
    color: #ffffff;
    background-color: #00000000;
    border: 1px solid #fff;
    font-weight: 700;
}
/* ----------- */

/* Register */
#register {
    background-color: #F6F8F7;
    padding: 70px 0;
}

#register h3 {
    font-weight: 700;
    text-align: center;
}

#register p {
    font-size: 12px;
    text-align: left;
}

.form-input {
    margin-bottom: 20px;
}

form textarea {
    height: 200px;
}

#register .form {
    margin: 40px auto;
    text-align: center;
}

form .btn {
    background-color: #005FAF;
    color: #fff;
    padding: 10px;
    width: 120px;
    font-weight: 700;
    text-align: center;
}
/* ------- */

/* Footer */
#footer {
    padding: 70px 0;
}

.social h5 {
    margin-bottom: 15px;
}

.social i {
    font-size: 2.8em;
    margin-right: 20px;
    color: rgb(61,68,92);
}

.footer {
    background: #ED1C24;
    color: #fff;
}

.contact span {
    color: #ED1C24;
}

.contact h6 {
    font-weight: 700;
    color: #000;
}

.social h5{
    color: #ED1C24;
    font-weight: 700;
}

.logo a img {
    height: 80px;
}

.video {
    width: 60%;
}

/* ----- */
@media ( width < 768px) {

    .hero {
        background-size: cover;
        background-position: left center;
        height: 700px;
    }

    .hero h1 {
        font-size: 3em;
        font-weight: 700;
    }

    .why-title{
        margin: 50px 0;
    }

    .box-benefit {
        width: 270px;
        height: 210px;
    }

    .box-benefit .title {
        margin: 15px 0;
    }

    #about-us img{
        background: #000;
        width: 100%;
    }

    .about-us {
        padding: 60px 35px;
    }

    .about-us p {
        margin-top: 30px;
    }

    .about-us .btn {
        margin-top: 30px;
    }

    #testimoni img{
        background: #000;
        width: 100%;
    }

    .testimoni {
        padding: 60px 35px;
    }

    .testimoni p {
        margin-top: 30px;
    }

    .testimoni .btn {
        margin-top: 30px;
    }

    .box-program {
        width: 300px;
        margin-bottom: 30px;
    }

    #consultation {
        padding: 50px 30px;
    }

    .content2 {
        margin-top: 50px;
    }

    .contact {
        margin-bottom: 50px;
        text-align: center;
    }

    .social {
        text-align: center;
        margin-bottom: 50px;
    }

    .logo {
        text-align: center;
    }

    .footer {
        text-align: center;
        padding: 30px 0;
    }

}

@media ( width > 767px) {
    nav {
        height: 90px;
    }

    .nav-item {
        margin: 0 10px;
    }

    .box-benefit {
        height: 200px;
    }

    #about-us {
        display: flex;
        justify-content: center;
        padding: 40px 0;
    }
    
    #about-us img {
        width: 50%;
    }

    .about-us {
        width: 50%;
        padding: 60px 35px;
    }

    .about-us .btn {
        margin-top: 30px;
    }

    #testimoni {
        display: flex;
        justify-content: center;
        padding: 40px 0;
    }
    
    #testimoni img {
        width: 50%;
    }

    .testimoni {
        width: 50%;
        padding: 60px 35px;
    }

    .testimoni .btn {
        margin-top: 30px;
    }

    .box-program {
        width: 250px;
    }

    .form {
        width: 600px;
        margin: auto;
    }

    .social {
        width: 300px;
        text-align: left;
        margin-left: auto;
    }

    .logo {
        text-align: center;
        margin-top: 70px;
    }

    .footer {
        padding: 20px 0;
    }

}

@media ( width > 1023px) {
    .nav-item {
        margin: 0 20px;
    } 

    .box-benefit {
        height: 230px;
    }

    #about-us {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 700px;
    }
    
    #about-us img {
        width: 650px;
        position: absolute;
        left: 80px;
    }

    .about-us {
        position: absolute;
        padding: 40px 50px;
        right: 80px;
        width: 500px;
    }

    .about-us p {
        margin-top: 20px;
    }

    .about-us .btn {
        margin-top: 20px;
    }

    #testimoni {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 700px;
    }
    
    #testimoni img {
        width: 650px;
        position: absolute;
        left: 80px;
    }

    .testimoni {
        position: absolute;
        padding: 40px 50px;
        right: 80px;
        width: 500px;
    }

    .testimoni p {
        margin-top: 20px;
    }

    .testimoni .btn {
        margin-top: 20px;
    }

    .content2 {
        text-align: center;
    }
}

@media (width > 1024px)  {
    .box-benefit {
        height: 200px!important;
    }

    #about-us img {
        width: 750px;
        position: absolute;
        left: 200px;
    }

    .about-us {
        position: absolute;
        padding: 40px 50px;
        right: 200px;
        width: 500px;
    }

    .about-us p {
        margin-top: 45px;
    }

    .about-us .btn {
        margin-top: 60px;
    }

    #testimoni img {
        width: 750px;
        position: absolute;
        left: 400px;
    }

    .testimoni {
        position: absolute;
        padding: 40px 50px;
        left: 200px;
        width: 500px;
    }

    .testimoni p {
        margin-top: 45px;
    }

    .testimoni .btn {
        margin-top: 60px;
    }

    .program-list {
        width: 900px;
        margin: auto;
    }
}

.timeline-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 16px;
}
  
.timeline-card {
    background-color: #fff;
    border: 1px solid #3b82f6;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
    max-width: 224px;
    width: 100%;
}
  
.timeline-card .header {
    align-items: center;
    background-color: #0d1b2a;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    color: #fff;
    display: flex;
    gap: 8px;
    padding: 16px;
}
  
.timeline-card .header i {
    color: #3b82f6;
}

.timeline-card .date {
    color: #2563eb;
    font-weight: 500;
    padding: 16px;
    text-align: center;
}

.timeline-title {
    font-size: 12px;
    font-weight: 600;
}
  
