* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    /* position: sticky;
    position: -webkit-sticky; */
    /* top: 0;
    z-index: 11; */
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.navbar li {
    list-style: none;
    position: relative;
    margin: 0 20px;
}

.navbar li a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    transition: 0.3s ease-in-out;
}

.navbar li a:hover {
    color: #EA565E;
}

.navbar li a.reg{
    color: #EA565E;
    font-weight: 700;
}

.navbar li a.reg:hover{
    color: #000000;
}

.hamburger {
    display: none;
}

@media ( width < 769px) {
    .nav{
        padding: 10px 30px;
    }
    .nav img {
        height: 30px;
    }
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 60px;
        right: 0px;
        width: 300px;
        height: 100vh;
        background: #ffffff;
        transition: 0.4s ease-in-out;
    }

    .hamburger {
        display: block;
        font-size: 1.7em;
    }

    .navbar li {
        margin-top: 20px;
    }

    .navbar.active {
        display: none;
        right: -300px;
        transition: 0.8s ease-in;
    }

}

@media ( width > 767px ) {
    .nav img{
        height: 60px;
    }
    .nav {
        padding: 10px 100px;
    }
}

@media (width > 1024px) {
    .nav {
        padding: 10px 100px;
    }
}